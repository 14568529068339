import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import Swal from 'sweetalert2'
import { environment } from 'src/environments/environment.prod';
const url_base_backend =environment.url_base_backend
@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css']
})

/**
 * Page Contact-One Component
 */
export class ContactoComponent implements OnInit {
  navClass = 'nav-light';

  constructor(
    private modalService: NgbModal,
    private fb: FormBuilder
    ) { }
  public contactenosForm = this.fb.group({
    nombre: ['', Validators.required],
    email: ['', [Validators.required, Validators.email ]],
    comentarios: ['', Validators.required]
  }) 
  ngOnInit(): void {
  }
  enviarContacto(){
    if ( this.contactenosForm.invalid ) {
      return
    }
    Swal.fire({
      title: 'Contact information',
      html:
      'Your comments have been sent, '+
      /* `<b>informacion@buenavistamancora.com</b>`+ */
      'soon we will contact you.'
      ,
      icon: 'success',
      confirmButtonText: 'ok',
      /* footer: 'Revise su correo' */
    })
    console.log('---------------------------------------');
    // console.log(`this.http.post(${url_base_backend}/contactenos/,this.contactenosForm.value)`);    
    console.log('this.http.post');
    console.log(`${url_base_backend}/contactenos/`);    
    console.log(this.contactenosForm.value);
    this.contactenosForm.reset()
    console.log('---------------------------------------');
    

  }
  mapView(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }
}
