<!-- Hero Start -->
<section class="bg-half d-table w-100" id="home" style="background: url('assets/images/company/nosotrosPortada.jpg'); background-size: 100% 100%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level title-heading">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark title"> About Us </h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">
                        We are located on the seafront and less than five minutes from the city center, visit us and enjoy the incomparable sun of Máncora.</h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Home</a></li>
                                <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">Company</a></li> -->
                                <li class="breadcrumb-item active" aria-current="page">About Us</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section">
    <div class="container">
        <div class="row align-items-center" id="counter">
            <div class="col-md-6">
                <img src="assets/images/company/misionVision.jpg" class="img-fluid" alt="">
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div class="ms-lg-4">
                    <div class="d-flex mb-4">
                        <span class="text-primary h1 mb-0"><span class="counter-value display-1 fw-bold" [CountTo]="anioActual-2009" [from]="0"
                  [duration]="4"></span> </span>
                        <h3 class="align-self-end ms-2" style="font-size: x-large;">Years of experience</h3>
                    </div>
                    <div class="section-title">
                        <h2 class="mb-4">Our Mission</h2>
                        <p style="font-size: large;">At <span class="text-primary fw-bold" style="font-family: 'Tillana'">Buena Vista Máncora</span> we provide a hotel service of excellence, developing products and services that guarantee satisfaction and peace of mind for our guests
                            during their stay, through individualized treatment by highly motivated staff, seeking to exceed the expectations of our guests. We have a committed human talent, in permanent development and continuous improvement, which allows
                            us to expand and maintain the market, providing total security to employees, customers, suppliers, partners and the community in general.</p>
                        <h2 class="mb-4">Our Vision</h2>
                        <p style="font-size: large;">We want each person who visits Mancora to participate in the positive energy that the Hotel <span class="text-primary fw-bold" style="font-family: 'Tillana'">Buena Vista Máncora</span> provides, become a company with solid hotel
                            prestige at a local, national and international level, promoting tourism and family development in the province of Máncora, offering a personalized service that allows a pleasant stay for our guests. Always committed through
                            continuous improvement within a framework of Mancore culture, values and hospitality.</p>
                        <a href="javascript:void(0)" routerLink="/inicio/contacto" class="btn btn-primary mt-3" style="font-size: large;">Contact Us</a>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <!-- <div class="container mt-5">
      <app-clients-logo></app-clients-logo>
    </div> -->
    <!--end container-->
</section>
<!--end section-->



<section class="section bg-light">
    <div class="container mt-100 mt-60" style="margin-top: -15px;">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 order-1 order-md-2">
                <img src="assets/images/travel/vistaLinda.jpg" class="img-fluid rounded shadow-md" alt="">
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title me-lg-5">
                    <h3 class="mb-4">Frequent questions: <span class="text-primary fw-bold">Buena Vista Máncora</span></h3>
                    <h3 style="font-size: large; color: blue;">Hotel Complex <span class="text-primary fw-bold">Always open !!!</span></h3>
                </div>

                <div class="faq-content mt-4 pt-2">
                    <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">What does Buena Vista Mancora offer?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Rooms for people with disabilities, air conditioning, relaxing and warm atmosphere, location on the seafront that really make Buena Vista the best accommodation option in Máncora</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">Is Buena Vista Mancora located near the sea?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Our location is in Mancora on the edge of the sea and less than five minutes walk from the center of Mancora, spend a few days enjoying the incomparable sea of Mancora
                                </p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">What amenities does Buena Vista Máncora have?
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">Buena Vista Máncora has hot water, wireless internet, cable TV, terraces overlooking the sea, beautiful gardens, etc. In which we can accommodate large groups such as families, students, couples, friends, etc.</p>
                            </ng-template>
                        </ngb-panel>
                        <ngb-panel cardClass="border-0 rounded shadow mb-2">
                            <ng-template ngbPanelTitle>
                                <div class="border-0 bg-light" id="headingtwo">
                                    <h4 class="title mb-0 text-dark">Additional information of Buena Vista Mancora
                                    </h4>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <p style="font-size: large;">The construction of our departments are a traditional building that will make your stay very pleasant. We have a large pool with 3 levels which will make you spend a few days enjoying the incomparable Mancora sun and total
                                    relaxation lulled by the sound of the Sea.</p>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>

                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->

<!-- Start -->
<section class="section bg-light pt-0">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="video-solution-cta position-relative" style="z-index: 1;">
                    <div class="position-relative">
                        <img src="assets/images/modelo.jpeg" class="img-fluid rounded-md shadow-lg" alt="">
                        <div class="play-icon">
                            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
                                <i class="mdi mdi-play text-primary rounded-circle bg-white shadow-lg"></i>
                            </a>
                            <ng-template #content let-modal>
                                <div class="modal-header">
                                    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                                </div>
                                <div class="modal-body">
                                    <!-- <youtube-player [videoId]="'yba7hPeTSjk'" height="450" width="780">
                    </youtube-player> -->
                                    <iframe height="450" width="780" src="https://www.youtube.com/embed/m2nhc9kfF1k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                    </iframe>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row -->
        <div class="feature-posts-placeholder bg-primary"></div>
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->