<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!-- Hero End -->

<section class="section pb-0" id="principal">

    <div class="container shadow bg-white rounded" style="padding-top: 30px;">

        <div class="row align-items-center">

            <div class="col-md-6">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen1}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen2}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen3}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen4}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div class="tiny-slide"><img src="{{habitacion.imagen5}}" class="img-fluid rounded" alt=""></div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
            <!--end col-->

            <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title ms-md-4">
                    <h1 class="heading mb-3 display-4 title-dark fw-bold title-dark">{{ habitacion.nombrehabitacion}}</h1>
                    <h4 class="text-success">S/. {{ habitacion.preciohabitacion}} x Night
                        <!-- <del class="text-danger ms-2"> Antes a S/. {{ (habitacion.preciohabitacion + 100)}} x Noche</del>  --></h4>
                    <ul class="list-unstyled text-warning h1 mb-0">
                        <li class="list-inline-item h5" *ngFor="let item of habitacion.servicios; let i = index"><i class="mdi mdi-star"></i></li>
                    </ul>

                    <app-reserva-detalle id="#reserva" [habitacion]="habitacion" (obtenerDatosReserva)="obtenerDatosReserva($event)" [prereserva]="prereserva"></app-reserva-detalle>

                    <ul class="row list-unstyled text-muted" style="margin-top: 15px;">
                        <div class="col-lg-12 col-md-6">
                            <li class="mb-0" style="font-size: large;"><span class="text-primary h3 me-2"><i class="uil uil-tag-alt align-middle"></i></span> Category: <span style="color: rgb(0, 17, 255);">{{ categoria }}</span></li>
                            <li class="mb-0" style="font-size: large;"><span class="text-primary h3 me-2"><i class="uil uil-users-alt align-middle"></i></span> Para <span>{{habitacion.numerodepersonas}}</span> personas</li>
                        </div>
                    </ul>
                    <!--end row-->
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!-- Agregado de servicios -->
    <div class="container shadow bg-white rounded" style="padding-top: 15px; margin-top: 15px;">

        <h3 class="mb-3">Amenities</h3>

        <div class="row list-unstyled text-muted align-items-center">

            <div class="col-lg-4 col-md-6 col-sm-12">
                <li class="mb-0" style="font-size: large;" *ngFor="let item of listaServicios[0]; let i=index"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span> {{item.nombreservicio}}
                </li>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <li class="mb-0" style="font-size: large;" *ngFor="let item of listaServicios[1]; let i=index"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span> {{item.nombreservicio}}
                </li>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
                <li class="mb-0" style="font-size: large;" *ngFor="let item of listaServicios[2]; let i=index"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span> {{item.nombreservicio}}
                </li>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->


    <!-- componente calendario -->
    <div class="container  mt-100 mt-60" style="margin-bottom: 50px;">
        <div class="row">
            <app-calendario [dataId]="habitacion.id"></app-calendario>
        </div>
    </div>
    <!-- dddddd -->

    <!-- <div class="container mt-100 mt-60">
      <div class="row">
        <div class="col-12">
          <ul ngbNav #nav="ngbNav"
            class="nav nav-pills shadow flex-column flex-sm-row d-md-inline-flex mb-0 p-1 bg-white rounded position-relative overflow-hidden"
            id="pills-tab" role="tablist">
            <li ngbNavItem class="nav-item m-1">
              <a ngbNavLink class="nav-link py-2 px-5 rounded">
                <div class="text-center">
                  <h6 class="mb-0">Descripción</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <p class="text-muted mb-0">Due to its widespread use as filler text for layouts, non-readability is of
                    great importance: human perception is tuned to recognize certain patterns and repetitions in texts. If
                    the distribution of letters and 'words' is random, the reader will not be distracted from making a
                    neutral judgement on the visual impact and readability of the typefaces (typography), or the
                    distribution of text on the page (layout or type area). For this reason, dummy text usually consists
                    of a more or less random series of words or syllables.</p>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem class="nav-item m-1">
              <a ngbNavLink class="nav-link py-2 px-5 rounded">
                <div class="text-center">
                  <h6 class="mb-0">Reglas de estadía</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <table class="table">
                    <tbody>
                      <tr>
                        <td style="width: 100px;">Color</td>
                        <td class="text-muted">Red, White, Black, Orange</td>
                      </tr>
  
                      <tr>
                        <td>Material</td>
                        <td class="text-muted">Cotton</td>
                      </tr>
  
                      <tr>
                        <td>Size</td>
                        <td class="text-muted">S, M, L, XL, XXL</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </ng-template>
            </li>
            <li ngbNavItem class="nav-item m-1">
              <a ngbNavLink class="nav-link py-2 px-5 rounded">
                <div class="text-center">
                  <h6 class="mb-0">Revisión</h6>
                </div>
              </a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-lg-6">
                    <ul class="media-list list-unstyled mb-0">
                      <li>
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center">
                            <a class="pe-3" href="#">
                              <img src="assets/images/client/01.jpg"
                                class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                            </a>
                            <div class="flex-1 commentor-detail">
                              <h6 class="mb-0"><a href="javascript:void(0)" class="text-dark media-heading">Lorenzo
                                  Peterson</a></h6>
                              <small class="text-muted">15th August, 2019 at 01:25 pm</small>
                            </div>
                          </div>
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                          </ul>
                        </div>
                        <div class="mt-3">
                          <p class="text-muted fst-italic p-3 bg-light rounded">" Awesome product "</p>
                        </div>
                      </li>
  
                      <li class="mt-4">
                        <div class="d-flex justify-content-between">
                          <div class="d-flex align-items-center">
                            <a class="pe-3" href="#">
                              <img src="assets/images/client/02.jpg"
                                class="img-fluid avatar avatar-md-sm rounded-circle shadow" alt="img">
                            </a>
                            <div class="flex-1 commentor-detail">
                              <h6 class="mb-0"><a href="javascript:void(0)" class="media-heading text-dark">Tammy
                                  Camacho</a></h6>
                              <small class="text-muted">15th August, 2019 at 05:44 pm</small>
                            </div>
                          </div>
                          <ul class="list-unstyled mb-0">
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                          </ul>
                        </div>
                        <div class="mt-3">
                          <p class="text-muted fst-italic p-3 bg-light rounded mb-0">" Good "</p>
                        </div>
                      </li>
                    </ul>
                  </div>
  
                  <div class="col-lg-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <form class="ms-lg-4">
                      <div class="row">
                        <div class="col-12">
                          <h5>Add your review:</h5>
                        </div>
                        <div class="col-12 mt-4">
                          <h6 class="small fw-bold">Your Rating:</h6>
                          <a href="javascript:void(0)" class="d-inline-block me-3">
                            <ul class="list-unstyled mb-0 small">
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                            </ul>
                          </a>
  
                          <a href="javascript:void(0)" class="d-inline-block me-3">
                            <ul class="list-unstyled mb-0 small">
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                            </ul>
                          </a>
  
                          <a href="javascript:void(0)" class="d-inline-block me-3">
                            <ul class="list-unstyled mb-0 small">
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                            </ul>
                          </a>
  
                          <a href="javascript:void(0)" class="d-inline-block me-3">
                            <ul class="list-unstyled mb-0 small">
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star-outline text-warning"></i></li>
                            </ul>
                          </a>
  
                          <a href="javascript:void(0)" class="d-inline-block">
                            <ul class="list-unstyled mb-0 small">
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                              <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                            </ul>
                          </a>
                        </div>
                        <div class="col-md-12 mt-3">
                          <div class="mb-3">
                            <label class="form-label">Your Review:</label>
                            <div class="form-icon position-relative">
                              <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                              <textarea id="message" placeholder="Your Comment" rows="5" name="message"
                                class="form-control ps-5" required=""></textarea>
                            </div>
                          </div>
                        </div>
  
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label">Name <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <i-feather name="user" class="fea icon-sm icons"></i-feather>
                              <input id="name" name="name" type="text" placeholder="Name" class="form-control ps-5"
                                required="">
                            </div>
                          </div>
                        </div>
  
                        <div class="col-lg-6">
                          <div class="mb-3">
                            <label class="form-label">Your Email <span class="text-danger">*</span></label>
                            <div class="form-icon position-relative">
                              <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                              <input id="email" type="email" placeholder="Email" name="email" class="form-control ps-5"
                                required="">
                            </div>
                          </div>
                        </div>
  
                        <div class="col-md-12">
                          <div class="send d-grid">
                            <button type="submit" class="btn btn-primary">Submit</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="col-12 tab-content mt-5"></div>
        </div>
      </div>
    </div> -->

</section>
<!--end section-->

<!-- Back to top -->
<a class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#principal'">
    <i-feather name="arrow-up" class="icons">Volver arriba</i-feather>
</a>
<!-- Back to top -->