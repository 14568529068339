<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home" style="background: url('assets/images/hotel/portadaContacto.jpg'); background-size: 100% 100%;">
    <div class="bg-overlay"></div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h1 class="display-4 title-dark text-white fw-bold mb-3 text-white title-dark"> Contact </h1>
                    <h3 class="text-white para-desc mb-0 mx-auto" style="font-size: x-large;">Get in touch with us to find out about the latest news we have for you.</h3>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/inicio">Home</a></li>
                                <!-- <li class="breadcrumb-item"><a href="javascript:void(0)">Page</a></li>
                  <li class="breadcrumb-item"><a href="javascript:void(0)">Contact</a></li> -->
                                <li class="breadcrumb-item active" aria-current="page">Contact</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Contact -->
<section class="section pb-0">
    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <div class="card border-0 text-center features feature-clean">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-phone d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-3">
                        <h2 class="fw-bold">Phone</h2>
                        <p style="font-size: x-large; color: blue;">Contact number</p>
                        <a href="tel:+152534-468-854" class="text-primary" style="font-size: x-large;">73258622</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-clean">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-envelope d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-3">
                        <h2 class="fw-bold">Email</h2>
                        <p style="font-size: x-large; color: blue;">Communication via email</p>
                        <a href="mailto:contact@example.com" class="text-primary" style="font-size: x-large;">buenavista.mancora@yahoo.com</a>
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="card border-0 text-center features feature-clean">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-map-marker d-block rounded h3 mb-0"></i>
                    </div>
                    <div class="content mt-3">
                        <h2 class="fw-bold">Location</h2>
                        <p style="font-size: x-large; color: blue;">Pasaje José Olaya #159 </p>
                        <a (click)="mapView(content)" href="javascript:void(0);" data-type="iframe" class="video-play-icon text-primary" style="font-size: x-large;">Vista en Google map</a>
                        <ng-template #content let-modal>
                            <div class="modal-header">
                                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                    </button>
                            </div>
                            <div class="modal-body">
                                <!-- <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                      height="450" width="750">
                    </iframe> -->
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.587686131091!2d-81.05472639999999!3d-4.1039796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9036914a63d87ed9%3A0x457ce3f368dea032!2sBuena%20Vista%20M%C3%A1ncora%20Hotel!5e0!3m2!1sen!2spe!4v1633813915125!5m2!1sen!2spe"
                                    width="600" height="450" height="450" width="750" style="border:0;" allowfullscreen="" loading="lazy">
                    </iframe>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-6 pt-2 pt-sm-0 order-2 order-md-1">
                <div class="card shadow rounded border-0">
                    <div class="card-body py-5">
                        <h2 class="card-title">Get in touch !!</h2>
                        <div class="custom-form mt-3">
                            <form class="p-4 shadow bg-white rounded" autocomplete="off" [formGroup]="contactenosForm" (ngSubmit)="enviarContacto()">
                                <p id="error-msg" class="mb-0"></p>
                                <div id="simple-msg"></div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Your Name <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="user" class="fea icon-sm icons"></i-feather>
                                                <input class="form-control ps-5" type="text" formControlName="nombre" id="nombre" placeholder="Your name :">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-6">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Your Email <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                                                <input class="form-control ps-5" type="email" formControlName="email" id="email" placeholder="Your email :">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Subject</label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="book" class="fea icon-sm icons"></i-feather>
                                                <input name="subject" id="subject" class="form-control ps-5" placeholder="Subject :">
                                            </div>
                                        </div>
                                    </div>
                                    <!--end col-->

                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label class="form-label" style="font-size: x-large;">Your Comments <span class="text-danger">*</span></label>
                                            <div class="form-icon position-relative">
                                                <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                                                <textarea class="form-control ps-5" type="text" formControlName="comentarios" id="comentarios" placeholder="Your Comments :"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="d-grid">
                                            <input class="submitBnt btn btn-primary" type="submit" id="search" name="search" value="Submit">
                                        </div>
                                    </div>
                                    <!--end col-->
                                </div>
                                <!--end row-->
                            </form>
                        </div>
                        <!--end custom-form-->
                    </div>
                </div>
            </div>
            <!--end col-->

            <div class="col-lg-7 col-md-6 order-1 order-md-2">
                <div class="card border-0">
                    <div class="card-body p-0">
                        <img src="assets/images/modeloContacto.jpeg" class="img-fluid" alt="">
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->

    <div class="container-fluid mt-100 mt-60">
        <div class="row">
            <div class="col-12 p-0">
                <div class="card map border-0">
                    <div class="card-body p-0">
                        <!-- <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                style="border:0" allowfullscreen></iframe> -->
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3979.587686131091!2d-81.05472639999999!3d-4.1039796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9036914a63d87ed9%3A0x457ce3f368dea032!2sBuena%20Vista%20M%C3%A1ncora%20Hotel!5e0!3m2!1sen!2spe!4v1633813915125!5m2!1sen!2spe"
                            width="600" height="450" height="450" width="750" style="border:0;" allowfullscreen="" loading="lazy">
                </iframe>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End contact -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->