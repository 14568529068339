import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-servicios',
  templateUrl: './servicios.component.html',
  styleUrls: ['./servicios.component.css'],
})

/**
 * Page Service Component
 */
export class ServiciosComponent implements OnInit {
  navClass = 'nav-light';

  constructor() { }

  /**
   * Services Data
   */
   servicesData = [
    {
      icon: "uil uil-wifi h1 text-primary",
      title: "Free Wi-Fi",
      description: "We have 24x7 Wi-Fi service"
    },
    {
      icon: "uil uil-swimmer h1 text-primary",
      title: "Pool",
      description: "Enjoy our pool facing the sea."
    },
    {
      icon: "uil uil-car h1 text-primary",
      title: "Parking",
      description: "We have parking in our establishment."
    },
    {
      icon: "uil uil-utensils h1 text-primary",
      title: "Restaurant",
      description: "Enjoy our gastronomy in our restaurant."
    },
    {
      icon: "uil uil-accessible-icon-alt h1 text-primary",
      title: "Disabled facilities",
      description: "We have facilities for people with disabilities."
    },
    {
      icon: "uil uil-tv-retro h1 text-primary",
      title: "Cable TV",
      description: "We have cable television 24 hours."
    },
    {
      icon: "uil uil-temperature-half h1 text-primary",
      title: "Air conditioning",
      description: "We have rooms with the air conditioning you are looking for."
    },
    {
      icon: "uil uil-lock-access h1 text-primary",
      title: "Strongbox",
      description: "Store your valuables without worry"
    },
    {
      icon: "uil uil-sunset h1 text-primary",
      title: "Terrace facing the sea",
      description: "The best view facing the sea, only at Buena Vista Mancora."
    },
    {
      icon: "uil uil-stretcher h1 text-primary",
      title: "Grill",
      description: "Share with your friends in our grill area."
    },
    {
      icon: "uil uil-wind h1 text-primary",
      title: "Whirlpool",
      description: "Enjoy a hydromassage facing the sea"
    },
    {
      icon: "uil uil-kid h1 text-primary",
      title: "Children's pool",
      description: "Swimming pool for the smug family."
    }
  ];

  /**
   * Client Testimonial Data
   */
  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Rodrigo Paredes",
      designation: "Gerente",
      message: `" It seems that only fragments of the original text remain in the Lorem Ipsum texts used today. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Barbara Jimenez",
      designation: "Administradora",
      message: `" One disadvantage of Lorum Ipsum is that in Latin certain letters appear more frequently than others. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Ana Oliver",
      designation: "Ingeniero",
      message: `" The most well-known dummy text is the 'Lorem Ipsum', which is said to have originated in the 16th century. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Kevin Aragon",
      designation: "Comercial",
      message: `" According to most sources, Lorum Ipsum can be traced back to a text composed by Cicero. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "Diana Gutierrez",
      designation: "Arquiteto",
      message: `" There is now an abundance of readable dummy texts. These are usually used when a text is required. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "Sergio Salazar",
      designation: "Diseñador",
      message: `" Thus, Lorem Ipsum has only limited suitability as a visual filler for German texts. "`
    }
  ];

  ngOnInit(): void {
  }

}
