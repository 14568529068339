<div class="col-lg-12">
    <form class="p-4 shadow bg-white rounded" autocomplete="off" [formGroup]="checkDisponibilidadForm" (ngSubmit)="buscarDisponibilidad()">
        <h2 class="mb-3">Prepare your reservation</h2>
        <div class="row text-start">
            <div ngClass="col-lg-3 col-md-3">
                <div class="mb-6 mb-lg-0">
                    <label class="form-label" style="font-size: large;"> Check in - Check out : </label>
                    <input class="form-control" style="text-align: center;" type="text" formControlName="picker" id="checkin" placeholder="Entry - Departure :" mwlFlatpickr [convertModelValue]="true" mode="range" minDate="today">

                </div>
            </div>
            <!--end col-->

            <div ngClass="col-lg-3 col-md-3">
                <div class="mb-3 mb-lg-0">
                    <label class="form-label" style="font-size: large;">Adults :</label>
                    <input class="form-control" style="text-align: center;" min="0" autocomplete="off" type="number" id="adult" formControlName="numAdultos" placeholder="Adults :" min="0" max="15">
                    <div class="col text-danger" style="text-align: center;">
                        <p *ngIf="campoNoValido('numAdultos')">la cantidad de adultos es obligatorio</p>
                    </div>
                </div>
            </div>
            <!--end col-->
            <div ngClass="col-lg-3 col-md-3">
                <div class="mb-3 mb-lg-0">
                    <label class="form-label" style="font-size: large;">Children : </label>
                    <input class="form-control" style="text-align: center;" min="0" autocomplete="off" type="number" id="ninos" formControlName="numNinios" placeholder="Children :" min="0" max="10">
                </div>
            </div>
            <!--end col-->

            <div ngClass="col-md-3">
                <div class="row align-items-center">

                    <div ngClass="col-md-12 mt-lg-4 pt-2 pt-lg-1">
                        <div class="d-grid">
                            <input type="submit" id="search" name="search" class="searchbtn btn btn-blue" value="Search">
                        </div>
                    </div>
                    <!--end col-->
                </div>
            </div>
        </div>

    </form>
</div>