<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h2 class="title"> Políticas de privacidad</h2>
                    <ul class="list-unstyled mt-4">
                        <li class="list-inline-item h6 date text-muted">
                            <h6 class="text-dark">Última revisión</h6> 23 de Diciembre de 2021
                        </li>
                    </ul>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/">Inicio</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Póliticas</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
      </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Privacy -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow rounded border-0">
                    <div class="card-body">
                        <h3 class="card-title">Reservation cancellation policies :</h3>
                        <h6 class="text-muted">A).- If the cancellation occurs 30 days or more prior to the Check In date, 90% of the amount paid will be refunded, less the transfer costs for these occasions.</h6>
                        <h6 class="text-muted">B).- If the cancellation occurs between 20 and 11 days prior to the arrival date, there is a penalty of 20% of the total amount of the original reservation, less the transfer costs for these occasions.</h6>
                        <h6 class="text-muted">C).- If the cancellation occurs between 0 and 2 days prior to the arrival date, there is a penalty of 100% of the total amount of the original reservation.</h6>
                        <h6 class="text-muted">D).- If the reservation is made directly at the hotel and you request to reduce your stay and/or number of guests, do not open any refund.</h6>
                        <h6 class="text-muted">E).- If after making the reservation you wish to reduce the number of nights and/or guests, there will be no cost reduction or refund.</h6>
                        <h6 class="text-muted">F).- In the event that passengers do not show up for the reservation on the agreed day, it will be considered a "No Show". In these cases there is no refund of money or transfer of reservation.</h6>
                        <h6 class="text-muted">G).- In the event of natural disasters, pandemics or other disturbances that prevent travelers from moving to or from the place of destination, or that do not guarantee a safe stay, 50% of the amount paid is reimbursed or postponed for a new date, subject to availability and rates of the establishment.</h6>
                        <h6 class="text-muted">H).- Illness or unexpected serious injury that affects the host or a member of the traveling group must present a medical certificate confirming that the person cannot stay or travel due to a serious and unexpected injury. Also, your issue date must be later than the date the reservation was made and you will need to submit it within 14 days of cancellation for a rescheduling of your stay (No refund).</h6>
                        <h6 class="text-muted">I).- If a date change is requested, there will be a 10% penalty charge, only in case the change is due to government restrictions there will be no additional charge.</h6>

                        <h6 class="text-muted">Sincerely yours</h6>

                        <h5 class="card-title">The management</h5>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Privacy -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->